import {Box, Divider, Stack, Typography} from '@mui/material';
import {useContext} from 'react';
import {Link} from 'react-router-dom';
import config from '../config';
import {RegionStateContext} from '../RegionContext';

function FooterElement(props) {
    function consentDrawer() {
        window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
    function doNotSell() {
        window.Osano.cm.showDoNotSell();
    }
    const regionState = useContext(RegionStateContext);
    const regionConfig = config[regionState.name];
    const translations = props.translations;

    if (translations) {
        return (
            <Box
                component={'footer'}
                sx={{
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: {xs: 'column-reverse', sm: 'row'},
                    justifyContent: 'space-between',
                    width: '100%',
                    maxWidth: '100%',
                    height: {xs: '68px', sm: '48px'},
                    alignItems: 'center',
                    padding: '4px',
                    marginTop: '0px'
                }}
            >
                <Stack width={'180px'} marginLeft={{xs: '10px', sm: '20px'}}>
                    <Typography color={'white'} fontSize={'12px'}>
                        ©{new Date().getFullYear()} PING. {translations.FooterCopyright}
                    </Typography>
                </Stack>
                <Stack
                    margin={'8px'}
                    direction={'row'}
                    divider={<Divider orientation='vertical' flexItem color='white' />}
                    justifyContent='center'
                    columnGap={1}
                >
                    <a
                        href={regionConfig.privacyPolicyLink}
                        target='_blank'
                        rel='noreferrer'
                        style={{textDecoration: 'none', color: 'white'}}
                        onMouseOver={e => (e.currentTarget.style.textDecoration = 'underline')}
                        onMouseOut={e => (e.currentTarget.style.textDecoration = 'none')}
                    >
                        <Typography color={'white'} fontSize={'12px'}>
                            {translations.PrivacyPolicy}
                        </Typography>
                    </a>
                    <Link
                        to='#'
                        onClick={consentDrawer}
                        style={{textDecoration: 'none', color: 'white'}}
                        onMouseOver={e => (e.currentTarget.style.textDecoration = 'underline')}
                        onMouseOut={e => (e.currentTarget.style.textDecoration = 'none')}
                    >
                        <Typography color={'white'} fontSize={'12px'}>
                            {translations.PrivacySettings}
                        </Typography>
                    </Link>
                    <Link
                        to='#'
                        onClick={doNotSell}
                        style={{textDecoration: 'none', color: 'white'}}
                        onMouseOver={e => (e.currentTarget.style.textDecoration = 'underline')}
                        onMouseOut={e => (e.currentTarget.style.textDecoration = 'none')}
                    >
                        <Typography color={'white'} fontSize={'12px'}>
                            {translations.SellingOfInformation}
                        </Typography>
                    </Link>
                    <a
                        href={regionConfig.termsOfUseLink}
                        target='_blank'
                        rel='noreferrer'
                        style={{textDecoration: 'none', color: 'white'}}
                        onMouseOver={e => (e.currentTarget.style.textDecoration = 'underline')}
                        onMouseOut={e => (e.currentTarget.style.textDecoration = 'none')}
                    >
                        <Typography color={'white'} fontSize={'12px'}>
                            {translations.TermsOfUse}
                        </Typography>
                    </a>
                </Stack>
                <Stack width={'180px'} display={{xs: 'none', sm: 'flex'}}></Stack>
            </Box>
        );
    } else {
        return <></>;
    }
}

export default FooterElement;
