import React, {useContext} from 'react';
import {Typography, Switch, Box} from '@mui/material';
import {RegionStateContext} from '../RegionContext';

function SwitchUnitsElement({
    translations,
    colors,
    distanceUnitImperial,
    setDistanceUnitImperial,
    speedUnitImperial,
    setSpeedUnitImperial,
    playerDistance,
    setPlayerDistance,
    playerHeight,
    setPlayerHeight,
    wtf,
    setWtf,
    playerSwingSpeed,
    setPlayerSwingSpeed,
    createReturnData
}) {
    const regionState = useContext(RegionStateContext);
    function swapSpeed(imperial) {
        if (imperial) {
            setPlayerSwingSpeed(Math.round(playerSwingSpeed / 0.447));
            return;
        } else {
            setPlayerSwingSpeed(Math.round(playerSwingSpeed * 0.447));
            return;
        }
    }

    function swapDistance(imperial) {
        if (imperial) {
            setPlayerDistance(Math.round(playerDistance / 0.914 / 5) * 5);
            setWtf(Math.round(wtf / 2.54));
            setPlayerHeight(Math.round(playerHeight / 2.54));
            return;
        } else {
            setPlayerDistance(Math.round((playerDistance * 0.914) / 5) * 5);
            setWtf(Math.round(wtf * 2.54));
            setPlayerHeight(Math.round(playerHeight * 2.54));
            return;
        }
    }

    return (
        <>
            <Box sx={{maxWidth: '90vw', width: '100%', textAlign: 'left', margin: '5px 0px 5px 10px'}}>
                {regionState.name !== '地域/言語' && (
                    <Typography variant='boldCopy' sx={{fontSize: '0.7rem', minHeight: '30px', color: colors[2]}}>
                        {`${translations.UnitsSwitch}`}Units
                    </Typography>
                )}
            </Box>

            <>
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px'}}>
                    <Typography sx={{fontSize: '10px'}}>{translations.CmMeters}</Typography>
                    <Switch
                        inputProps={{
                            'aria-label': translations.DistanceSystemSwitchAria
                        }}
                        size='small'
                        checked={distanceUnitImperial}
                        onClick={() => {
                            swapDistance(!distanceUnitImperial);

                            setDistanceUnitImperial(!distanceUnitImperial);
                            localStorage.setItem(`PingJuniorWebFitDistance`, String(!distanceUnitImperial));
                        }}
                    />
                    <Typography sx={{fontSize: '10px'}}>{translations.InchYards}</Typography>
                </Box>
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px'}}>
                    <Typography sx={{fontSize: '10px'}}>{translations.MetersPerSecond}</Typography>
                    <Switch
                        inputProps={{
                            'aria-label': translations.SpeedSystemSwitchAria
                        }}
                        size='small'
                        checked={speedUnitImperial}
                        onClick={() => {
                            swapSpeed(!speedUnitImperial);

                            setSpeedUnitImperial(!speedUnitImperial);
                            localStorage.setItem(`PingJuniorWebFitSpeed`, String(!speedUnitImperial));
                        }}
                    />
                    <Typography sx={{fontSize: '10px'}}>{translations.MilesPerHour}</Typography>
                </Box>
                <Box sx={{width: '86%', margin: '10px 0px 10px 7%', borderBottom: `1px solid ${colors[4]}`}} />
                <Typography sx={{width: '86%', marginLeft: '7%', fontSize: '10px'}}>{translations.HaveAQuestion}</Typography>
                <Typography sx={{width: '86%', marginLeft: '7%', fontSize: '10px'}}>
                    {translations.VisitFaq1}{' '}
                    <button
                        onClick={() => createReturnData()}
                        aria-label={translations.GoToFaqAriaLabel}
                        tabIndex={1}
                        style={{
                            'all': 'unset',
                            'position': 'relative',
                            '&:focus': {
                                color: colors[2]
                            },
                            'cursor': 'pointer',
                            'transition': '0.3s all'
                        }}
                    >
                        <span style={{textDecoration: 'underline', fontSize: '10px', color: colors[2]}}>{translations.VisitFaq2}</span>
                    </button>{' '}
                    {translations.VisitFaq3}
                </Typography>
            </>
        </>
    );
}

export default SwitchUnitsElement;
